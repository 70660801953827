import {
    SELECT_PROJECT_LIST_REQUEST,
    SELECT_PROJECT_LIST_SUCCESS,
    SELECT_PROJECT_LIST_FALUIRE
} from './selectProjectListActionType';

const initialState = {
    loading: false,
    selectProjectList: null,
    error: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_PROJECT_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };


        case SELECT_PROJECT_LIST_SUCCESS:
            return {
                ...state,
                selectProjectList: action.payload,
                error: '',
                loading: false,
            };

        case SELECT_PROJECT_LIST_FALUIRE:
            return {
                ...state,
                loading: false,
                selectProjectList: null,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
