import {
    ASSIGN_PROJECT_LIST_REQUEST,
    ASSIGN_PROJECT_LIST_SUCCESS,
    ASSIGN_PROJECT_LIST_FALUIRE
} from './assignProjectListActionType';

const initialState = {
    loading: false,
    assignedProjectList: null,
    error: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ASSIGN_PROJECT_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };


        case ASSIGN_PROJECT_LIST_SUCCESS:
            return {
                ...state,
                assignedProjectList: action.payload,
                error: '',
                loading: false,
            };

        case ASSIGN_PROJECT_LIST_FALUIRE:
            return {
                ...state,
                loading: false,
                assignedProjectList: null,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
