import { combineReducers } from 'redux';
import loginReducer from './login/loginReducer'
import signupReducer from './signup/signupReducer'
import forgotPasswordInfo from './forgotPassword/forgotPasswordReducer'
import validateOTPInfo from './validateOTP/validateOTPReducer'
import changePasswordInfo from './changePassword/changePasswordReducer'
import allOrganisationList from './loadOrganisations/loadOrganisationsReducer'
import allProjectList from './loadAllProjects/loadAllProjectsReducer'
import isCreateProjectSuccess from './createProject/createProjectReducer'
import snackBarMessage from './snackBarMessage/snackBarMessageReducer'
import allUsersList from './loadAllUsers/loadAllUsersReducer'
import allOwnerList from './loadAllOwners/loadAllOwnersReducer'
import allSuggestedUsersList from './userNameSuggestions/userNameSuggestionsReducer'
import allProjectBasedUponSelectedOrganisationID from './loadAllProjectsBasedUponOrganisation/loadAllProjectsBasedUponOrganisationReducer'
import isUserNameAvailable from './validateUserName/validateUserNameReducer'
import allTemplateList from './loadAllTemplate/loadAllTemplateReducer'
import selectedProjectName from './selectedProjectName/selectedProjectNameReducer'
import globalSelectedProjectName from './globalProjectNameSelection/globalProjectNameSelectionReducer'
import updateRolesInfo from './updateRoles/updateRolesReducer'
import listRolesInfo from './listRoles/listRolesReducer'
import addRolesInfo from './addRoles/addRolesReducer'
import allRolesForCreatingRoles from './rolesForCreatingRoleListing/rolesForCreatingRoleListingReducer'
import particularProjectInfo from './loadProjectByID/loadProjectByIDReducer'
import deleteProjectInfo from './deleteProjectByProjectID/deleteProjectByProjectIDReducer'
import updateProjectInfo from './updateProjectByProjectID/updateProjectByProjectIDReducer'
import particularUserInfo from './loadUserByUSerID/loadUserByUSerIDReducer'
import isDeleteUserSuccess from './deleteUserByUserID/deleteUserByUserIDReducer'
import isUpdateUserSuccess from './updateUserByUserID/updateUserByUserIDReducer'
import particularListRolesInfo from './listRolesByRoleID/listRolesByRoleIDReducer'
import deleteRolesInfo from './deleteRoleByID/deleteRoleByIDReducer'
import addDocumentsInfo from './addDocuments/addDocumentsReducer'
import allDocumentsInfo from './listAllDocuments/listAllDocumentsReducer'
import updateDocumentsInfo from './updateDocuments/updateDocumentsReducer'
import isCreateUserSuccess from './createUser/createUserReducer'
import particularTemplateOrFolderInfo from './loadTemplateOrFolderByID/loadTemplateOrFolderByIDReducer'
import particularDocumentsInfo from './getDocumentsByID/getDocumentsByIDReducer'
import documentVersionInfo from './documentVersionHostory/documentVersionHostoryReducer'
import previewDocumentInfo from './previewDocuments/previewDocumentsReducer'
import progressEventInfo from './progressBarCount/progressBarCountReducer'
import allDocumentsTrashList from './listAllDocumentTrashList/listAllDocumentTrashListReducer'
import chatUserInfo from './loginChat/loginChatReducer'
import isFolderUploadedSuccess from './uploadFolder/uploadFolderReducer'
import tempalteDataForUpdation from './loadTemplateByID/loadTemplateByIDReducer'
import loggedInUserPermissions from './loadLoggedInUserPermission/loadLoggedInUserPermissionReducer'
import allTrashTemplateList from './loadAllTrashTemplate/loadAllTrashTemplateReducer'
import allTrashProjectList from './loadAllTrashProjects/loadAllTrashProjectsReducer'
import updateThermitUnitMaxAndMin from './updateThermitUnitMinAndMixValue/updateThermitUnitMinAndMixValueReducer'
//new code.
import renameDocumentInfo from './renameDocuments/renameDocumentsReducer'
import copyDocument from './copyDocuments/copyDocumentsReducer'
import fetchRecoverTrashDoc from './deleteDocuments/deleteDocumentsReducer'
import downloadDocument from './downloadDocuments/downloadDocumentsReducer'
import renameTemplate from './renameTemplate/renameTemplateReducer'
import duplicateTemplate from './duplicateTemplate/duplicateTemplateReducer'
import isCreatedDocumentFolderSuccess from './createDocumentFolder/createDocumentFolderReducer'
import deleteTemplateTypes from './deleteTemplate/deleteTemplateReducer'
import fetchDuplicateTemplate from './duplicateTemplate/duplicateTemplateReducer'
import fetchCreateTemplate from './createTemplate/createTemplateReducer'
import createTemplate from './createTemplate/createTemplateReducer'
import fetchDeleteUser from './deleteUserByUserID/deleteUserByUserIDReducer'
import fetchDeleteRoles from './deleteRoles/deleteRolesReducer'
import deleteOwnerInfo from './deleteOwnerByOwnerID/deleteOwnerByOwnerIDReducer'
import particularOwnerInfo from './loadOwnerByOwnerID/loadOwnerByOwnerIDReducer'
import isUpdateOwnerSuccess from './updateOwnerByOwnerID/updateOwnerByOwnerIDReducer'
import allTrashOwnerList from './loadAllTrashOwners/loadAllTrashOwnersReducer'
import allProjectTemplateListForProjectSection from './listAllProjectTemplateForProjectSection/listAllProjectTemplateForProjectSectionReducer'

//new code.
import addProjectTemplateInfo from './addProjectTemplate/addProjectTemplateReducer'
import allprojectTemplateList from './listAllProjectTemplate/listAllProjectTemplateReducer'
import isCreateOwnerSuccess from './createOwner/createOwnerReducer'
import particularProjectTemplateTaskInfo from './getProjectTemplateTaskByID/getProjectTemplateTaskByIDReducer'
import projectTemplateTaskGroupList from './listAllProjectTemplateTaskGroupByProjectID/listAllProjectTemplateTaskGroupByProjectIDReducer'
import allTrashProjectTemplateList from './listAllTrashProjectTemplate/listAllTrashProjectTemplateReducer'
import projectTemplateTaskGroupInfo from './loadProjectTemplateTaskGroupByID/loadProjectTemplateTaskGroupByIDReducer'
import internetConnectionMessage from './setInternetConnectionMessage/setInternetConnectionMessageReducer'
import allTemplateTaskList from './listAllTemplateTask/listAllTemplateTaskReducer'
import particularTemplateTaskInfo from './getTemplateTaskByID/getTemplateTaskByIDReducer'
import completedTaskList from './completedTaskList/completedTaskListReducer'
import completedTaskByIDInfo from './getCompletedTaskByID/getCompletedTaskByIDReducer'
import submitCompleteTask from './submitCompleteTask/submitCompleteTaskReducer'
import deletedUserList from './loadDeletedPeopleList/loadDeletedPeopleListReducer'
import globalTemplateName from './setGlobalTemplateName/setGlobalTemplateNameReducer'
import allTemplateFolderList from './listAllTemplateFolder/listAllTemplateFolderReducer'
import createTemplateFolder from './createTemplateFolder/createTemplateFolderReducer'
import isFolderOpen from './openTemplateFolder/openTemplateFolderReducer'
import allDeletedTemplateTaskList from './listAllDeletedTemplateTask/listAllDeletedTemplateTaskReducer'
import myTaskListingData from './myTaskListing/myTaskListingReducer'
import particularCompanyInformation from './loadCompanyByID/loadCompanyByIDReducer'
import documentListForTask from './documentListForTask/documentListForTaskReducer'
import selectedOrganisationData from './selectedOrganisationData/selectedOrganisationDataReducer'
import publishedTemplateListingData from './publishedTemplateListing/publishedTemplateListingReducer'
import isTemplateUpdated from './isTemplateChanged/isTemplateChangedReducer'
import trashRolesList from './loadTrashRoles/loadTrashRolesReducer'
import allDeletedCompany from './listDeletedCompany/listDeletedCompanyReducer'
import restoreDeletedCompany from './restoreDeletedCompany/restoreDeletedCompanyReducer'
import restoreRole from './restoreTrashRoles/restoreTrashRolesReducer'
import allProjectListByCompanyID from './loadAllProjectsByCompanyID/loadAllProjectsByCompanyIDReducer'
import allDocListForProjAss from './loadAllDocForProjectAssociation/loadAllDocForProjectAssociationReducer'
import allDeletedMyTask from './listAllMyDeletedTaskList/listAllMyDeletedTaskListReducer'
import isCreateOrgSuccess from './createOrganisation/createOrganisationReducer'
import addProjectTemplateTask from './addProjectTemplateTask/addProjectTemplateTaskReducer'
import updateProjectTemplateTask from './updateProjectTemplateTask/updateProjectTemplateTaskReducer'
import addSpecialTaskInfo from './addSpecialTask/addSpecialTaskReducer'
import updateCompanyByID from './updateCompanyByID/updateCompanyByIDReducer'
import allDeletedTemplateTaskListForProject from './listAllDeletedTemplateTaskForProject/listAllDeletedTemplateTaskForProjectReducer'
import updateUserProfile from './updateUserProfile/updateUserProfileReducer'
import verifyThermitLoginInformation from './verifyThermitLogin/verifyThermitLoginReducer'
import userInfoBasedToken from './loginBaseUponToken/loginBaseUponTokenReducer'
import projectSettingsInformation from './loadProjectSettings/loadProjectSettingsReducer'
import allThermityProjectListing from './loadAllThermityProject/loadAllThermityProjectReducer'
import saveThermitLogin from './saveThermitLogin/saveThermitLoginReducer'
import allThermitLog from './loadThermitLog/loadThermitLogReducer'
import allThermitData from './loadThermitData/loadThermitDataReducer'
import isRenameThermitUnitAreaSuccess from './renameThermitUnitArea/renameThermitUnitAreaReducer'
import isMoveThermitUnitSuccess from './moveThermitUnitFronOnePlaceToAnother/moveThermitUnitFronOnePlaceToAnotherReducer'
import subTaskBasedOnTemplate from './loadSubtaskBasedOnTemplateAndProject/loadSubtaskBasedOnTemplateAndProjectReducer'
import completedTaskReportList from './completedTaskReport/completedTaskReportReducer'
import completedDeviationReportList from './completedDeviationReports/completedDeviationReportsReducer'
import allTemplateUpcomingTaskList from './listAllUpcomingTemplateTask/listAllUpcomingTemplateTaskReducer'
import allLateTemplateTaskList from './listAllLateTemplateTask/listAllLateTemplateTaskReducer'
import downloadCompletedDeviationReportList from './downloadCompletedReports/downloadCompletedReportsReducer'
import thermitDataWithoutMaxMin from './loadThermitUnitDataWithoutMaxMin/loadThermitDataReducer'
import unitAreaForThermit from './getProjectTemplateSubTaskByID/getProjectTemplateSubTaskByIDReducer'
import thermitUnitDetailByUnitID from './thermitUnitDetailByUnitID/thermitUnitDetailByUnitIDReducer'
import multiDeleteTask from './multiDeleteTask/multiDeleteTaskReducer'
import myLateTaskListingData from './myLateTaskListing/myLateTaskListingReducer'
import myUpcomingTaskListingData from './myUpcomingTaskListing/myUpcomingTaskListingReducer'
import allTaskCount from './loadTaskCount/loadTaskCountReducer'
import allMasterTemplateList from './loadAllMasterTemplate/loadAllMasterTemplateReducer'
import deleteDocumentsPermanentlyInfo from './deleteDocumentsPermanently/deleteDocumentsPermanentlyReducer'
import allDocumentsForProjectAssociation from './listAllDocumentForProjectAssociation/listAllDocumentForProjectAssociationReducer'
import documentAssociatedProjectList from './findAssociativeDocumentProject/findAssociativeDocumentProjectReducer'
import assignTaskToAnotherUser from './assignTaskToAnotherUser/assignTaskToAnotherUserReducer'
import peopleAssociatedWithOrganization from './findPeopleBasedProjectIDAndUserID/findPeopleBasedProjectIDAndUserIDReducer'
import deleteProjectTemplateTaskLane from './deleteProjectTemplateTaskLane/deleteProjectTemplateTaskLaneReducer'
import deleteProjectTemplateTaskLaneByPRojectID from './deleteProjectTemplateTaskLaneByProjectID/deleteProjectTemplateTaskLaneByProjectIDReducer'
import hideOpenedTask from './hideOpenedTaskPopupView/hideOpenedTaskPopupViewReducer'
import restoreProjectTemplateTaskForProjectPage from './restoreDeletedProjectTemplate/restoreDeletedProjectTemplateReducer'
import allDeletedProjectTemplateTaskForProjectPage from './listAllDeletedProjectTemplate/listAllDeletedProjectTemplateReducer'
import draftCompletedTaskList from './fetchDraftCompletedTaskList/fetchDraftCompletedTaskListReducer'
import particularSpecialTemplateTaskInfo from './getSpecialTaskByTemplateID/getSpecialTaskByTemplateIDReducer'
import allMasterTemplateForReplicationList from './loadAllTemplateForDeletionOldTemplate/loadAllTemplateForDeletionOldTemplateReducer'
import loggedinUserInfo from './getParticularUserInfo/getParticularUserInfoReducer'
import allTemplateSpecialUpcomingTaskList from './listAllUpcomingSpecialTask/listAllUpcomingSpecialTaskReducer'
import updateProjectSettings from './updateProjectSettings/updateProjectSettingsReducer'
import thermitDepartmentListBasedOnProjectID from './thermitDepartmentBasedUponProjectID/thermitDepartmentBasedUponProjectIDReducer'
import unitWithDeviationWithoutMinMax from './unitWithDeviationWithNoMinMAx/unitWithDeviationWithNoMinMAxReducer'
import unitWithDeviationWithMinMax from './unitWithDeviationWithMaxMin/unitWithDeviationWithMaxMinReducer'
import publishedTemplateNameBYIDList from './templateNameByIDCompletedTask/templateNameByIDCompletedTaskReducer'
import allThermitGridData from './loadThermitDataWithGrid/loadThermitDataWithGridReducers'
import assignedProjectList from './assignProjectList/assignProjectListReducer'
import selectProjectList from './selectProjectList/selectProjectListReducer'
const appReducer = combineReducers({
  allThermitGridData: allThermitGridData,
  fetchDeleteRoles: fetchDeleteRoles,
  loggedinUserInfo: loggedinUserInfo,
  particularSpecialTemplateTaskInfo: particularSpecialTemplateTaskInfo,
  allMasterTemplateForReplicationList: allMasterTemplateForReplicationList,
  draftCompletedTaskList: draftCompletedTaskList,
  restoreProjectTemplateTaskForProjectPage: restoreProjectTemplateTaskForProjectPage,
  allDeletedProjectTemplateTaskForProjectPage: allDeletedProjectTemplateTaskForProjectPage,
  hideOpenedTask: hideOpenedTask,
  updateThermitUnitMaxAndMin: updateThermitUnitMaxAndMin,
  deleteProjectTemplateTaskLaneByPRojectID: deleteProjectTemplateTaskLaneByPRojectID,
  deleteProjectTemplateTaskLane: deleteProjectTemplateTaskLane,
  allDocumentsForProjectAssociation: allDocumentsForProjectAssociation,
  deleteDocumentsPermanentlyInfo: deleteDocumentsPermanentlyInfo,
  myLateTaskListingData: myLateTaskListingData,
  myUpcomingTaskListingData: myUpcomingTaskListingData,
  allTaskCount: allTaskCount,
  multiDeleteTask: multiDeleteTask,
  thermitUnitDetailByUnitID: thermitUnitDetailByUnitID,
  unitAreaForThermit: unitAreaForThermit,
  thermitDataWithoutMaxMin: thermitDataWithoutMaxMin,
  allTemplateUpcomingTaskList: allTemplateUpcomingTaskList,
  allLateTemplateTaskList: allLateTemplateTaskList,
  verifyThermitLoginInformation: verifyThermitLoginInformation,
  completedDeviationReportList: completedDeviationReportList,
  completedTaskReportList: completedTaskReportList,
  isRenameThermitUnitAreaSuccess: isRenameThermitUnitAreaSuccess,
  isMoveThermitUnitSuccess: isMoveThermitUnitSuccess,
  subTaskBasedOnTemplate: subTaskBasedOnTemplate,
  allThermitData: allThermitData,
  saveThermitLogin: saveThermitLogin,
  allThermityProjectListing: allThermityProjectListing,
  allThermitLog: allThermitLog,
  projectSettingsInformation: projectSettingsInformation,
  userInfoBasedToken: userInfoBasedToken,
  updateUserProfile: updateUserProfile,
  updateProjectTemplateTask: updateProjectTemplateTask,
  updateCompanyByID: updateCompanyByID,
  addSpecialTaskInfo: addSpecialTaskInfo,
  isCreateOrgSuccess: isCreateOrgSuccess,
  addProjectTemplateTask: addProjectTemplateTask,
  allProjectTemplateListForProjectSection: allProjectTemplateListForProjectSection,
  restoreDeletedCompany: restoreDeletedCompany,
  allDeletedMyTask: allDeletedMyTask,
  allProjectListByCompanyID: allProjectListByCompanyID,
  allDeletedTemplateTaskListForProject,
  allDocListForProjAss: allDocListForProjAss,
  restoreRole: restoreRole,
  allDeletedCompany: allDeletedCompany,
  createTemplate: createTemplate,
  isTemplateUpdated: isTemplateUpdated,
  trashRolesList: trashRolesList,
  documentListForTask: documentListForTask,
  selectedOrganisationData: selectedOrganisationData,
  isFolderOpen: isFolderOpen,
  myTaskListingData: myTaskListingData,
  publishedTemplateListingData: publishedTemplateListingData,
  particularCompanyInformation: particularCompanyInformation,
  allDeletedTemplateTaskList: allDeletedTemplateTaskList,
  createTemplateFolder: createTemplateFolder,
  allTemplateFolderList: allTemplateFolderList,
  submitCompleteTask: submitCompleteTask,
  globalTemplateName: globalTemplateName,
  deletedUserList: deletedUserList,
  particularTemplateTaskInfo: particularTemplateTaskInfo,
  completedTaskByIDInfo: completedTaskByIDInfo,
  completedTaskList: completedTaskList,
  allTemplateTaskList: allTemplateTaskList,
  allTrashProjectTemplateList: allTrashProjectTemplateList,
  internetConnectionMessage: internetConnectionMessage,
  projectTemplateTaskGroupInfo: projectTemplateTaskGroupInfo,
  projectTemplateTaskGroupList: projectTemplateTaskGroupList,
  particularProjectTemplateTaskInfo: particularProjectTemplateTaskInfo,
  addProjectTemplateInfo: addProjectTemplateInfo,
  allprojectTemplateList: allprojectTemplateList,
  fetchDeleteUser: fetchDeleteUser,
  fetchCreateTemplate: fetchCreateTemplate,
  fetchDuplicateTemplate: fetchDuplicateTemplate,
  deleteTemplateTypes: deleteTemplateTypes,
  isCreatedDocumentFolderSuccess: isCreatedDocumentFolderSuccess,
  duplicateTemplate: duplicateTemplate,
  renameTemplate: renameTemplate,
  downloadDocument: downloadDocument,
  fetchRecoverTrashDoc: fetchRecoverTrashDoc,
  copyDocument: copyDocument,
  renameDocumentInfo: renameDocumentInfo,
  isUserSignUp: signupReducer,
  allTrashTemplateList: allTrashTemplateList,
  allTrashProjectList: allTrashProjectList,
  loggedInUserPermissions: loggedInUserPermissions,
  tempalteDataForUpdation: tempalteDataForUpdation,
  previewDocumentInfo: previewDocumentInfo,
  isFolderUploadedSuccess: isFolderUploadedSuccess,
  chatUserInfo: chatUserInfo,
  allDocumentsTrashList: allDocumentsTrashList,
  progressEventInfo: progressEventInfo,
  particularTemplateOrFolderInfo: particularTemplateOrFolderInfo,
  documentVersionInfo: documentVersionInfo,
  particularDocumentsInfo: particularDocumentsInfo,
  addDocumentsInfo: addDocumentsInfo,
  updateDocumentsInfo: updateDocumentsInfo,
  allDocumentsInfo: allDocumentsInfo,
  deleteRolesInfo: deleteRolesInfo,
  particularListRolesInfo: particularListRolesInfo,
  isDeleteUserSuccess: isDeleteUserSuccess,
  isUpdateUserSuccess: isUpdateUserSuccess,
  particularUserInfo: particularUserInfo,
  updateProjectInfo: updateProjectInfo,
  allRolesForCreatingRoles: allRolesForCreatingRoles,
  particularProjectInfo: particularProjectInfo,
  userInfo: loginReducer,
  forgotPasswordInfo: forgotPasswordInfo,
  validateOTPInfo: validateOTPInfo,
  changePasswordInfo: changePasswordInfo,
  allOrganisationList: allOrganisationList,
  allProjectList: allProjectList,
  isCreateProjectSuccess: isCreateProjectSuccess,
  snackBarMessage: snackBarMessage,
  allUsersList: allUsersList,
  allSuggestedUsersList: allSuggestedUsersList,
  allProjectBasedUponSelectedOrganisationID: allProjectBasedUponSelectedOrganisationID,
  isUserNameAvailable: isUserNameAvailable,
  allTemplateList: allTemplateList,
  selectedProjectName: selectedProjectName,
  globalSelectedProjectName: globalSelectedProjectName,
  updateRolesInfo: updateRolesInfo,
  listRolesInfo: listRolesInfo,
  addRolesInfo: addRolesInfo,
  deleteProjectInfo: deleteProjectInfo,
  isCreateUserSuccess: isCreateUserSuccess,
  allOwnerList: allOwnerList,
  deleteOwnerInfo: deleteOwnerInfo,
  particularOwnerInfo: particularOwnerInfo,
  isUpdateOwnerSuccess: isUpdateOwnerSuccess,
  allTrashOwnerList: allTrashOwnerList,
  isCreateOwnerSuccess: isCreateOwnerSuccess,
  downloadCompletedDeviationReportList: downloadCompletedDeviationReportList,
  allMasterTemplateList: allMasterTemplateList,
  documentAssociatedProjectList: documentAssociatedProjectList,
  assignTaskToAnotherUser: assignTaskToAnotherUser,
  peopleAssociatedWithOrganization: peopleAssociatedWithOrganization,
  allTemplateSpecialUpcomingTaskList: allTemplateSpecialUpcomingTaskList,
  updateProjectSettings: updateProjectSettings,
  thermitDepartmentListBasedOnProjectID: thermitDepartmentListBasedOnProjectID,
  unitWithDeviationWithoutMinMax: unitWithDeviationWithoutMinMax,
  unitWithDeviationWithMinMax: unitWithDeviationWithMinMax,
  publishedTemplateNameBYIDList: publishedTemplateNameBYIDList,
  assignedProjectList: assignedProjectList,
  selectProjectList: selectProjectList
});

const rootReducer = (state, action) => {
  if (action.type === 'FETCH_LOGOUT_SUCCESS') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer;
